//
//
//
//
//
//
//
//

import vPagination from 'vue-plain-pagination';

export default {
  components: { vPagination },
  props: ['total_label', 'total_available_label'],
  data() {
    return {
      bootstrapPaginationClasses: {
        ul: 'pagination m-0 align-self-center',
        li: 'page-item',
        liActive: 'active',
        liDisable: 'disabled',
        button: 'page-link',
      },
    }
  },
  computed: {
    currentPage : {
      get: function() {
        return this.$store.state.page
      },

      set: function(val) {
        if (val !== 0) {
          this.$store.dispatch('setPage', val);
        }
      },

    },
    total() {
      return parseInt(this.$store.state.total_pages);
    },
    total_objects() {
      return this.$store.state.total;
    },
  },
}

