import Swiper from 'swiper';
export default {
  init() {
    // if we have a swiper element on the page, initialize swiper
    if (document.querySelector('.start-slider .swiper-container')) {
      new Swiper('.start-slider .swiper-container', {
        slidesPerView: 1,
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 5000,
        },
        autoHeight: true,
        navigation: {
          nextEl: '.start-slider .swiper-button-next',
          prevEl: '.start-slider .swiper-button-prev',
        },
      });
    }

    if (document.querySelector('.department-slider .swiper-container')) {
      new Swiper('.department-slider .swiper-container', {
        slidesPerView: 1,
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        autoplay: {
          delay: 5000,
        },
        autoHeight: true,
        navigation: {
          nextEl: '.department-slider-slider .swiper-button-next',
          prevEl: '.department-slider-slider .swiper-button-prev',
        },
        pagination: {
          el: '.department-slider .swiper-pagination',
          clickable: true,
        },
      });
    }
  },



  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
