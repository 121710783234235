var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.state.objects.length > 0
    ? _c(
        "div",
        { staticClass: "object-list" },
        _vm._l(_vm.objects, function(object) {
          return _c(
            "div",
            { key: object.id, staticClass: "bg-white shadow mb-4" },
            [
              _c("div", { staticClass: "row ps-2 py-2 pe-2 " }, [
                _c(
                  "div",
                  { staticClass: "col-lg-auto col-md-4 d-flex" },
                  [
                    _c(
                      "swiper",
                      { attrs: { options: _vm.swiperOptions } },
                      [
                        _vm._l(object.images, function(banner, index) {
                          return _c(
                            "swiper-slide",
                            {
                              key: banner,
                              staticClass: "d-flex align-items-center"
                            },
                            [
                              !_vm.isMobile
                                ? _c("img", {
                                    staticClass:
                                      "img-fluid align-self-center d-none d-xl-block",
                                    attrs: { src: banner }
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isMobile
                                ? _c("img", {
                                    staticClass:
                                      "img-fluid align-self-center d-block d-xl-none w-100",
                                    attrs: { src: object.image_mobile[index] }
                                  })
                                : _vm._e()
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "swiper-button-prev",
                          attrs: { slot: "button-prev" },
                          slot: "button-prev"
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "swiper-button-next",
                          attrs: { slot: "button-next" },
                          slot: "button-next"
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col " }, [
                  _c("div", { staticClass: "row h-100 d-grid d-lg-flex" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col d-flex justify-content-between flex-column"
                      },
                      [
                        _c("div", { staticClass: "row mb-2 flex-grow-1" }, [
                          _c(
                            "div",
                            { staticClass: "col" },
                            [
                              _c("small", [
                                _vm._v(
                                  _vm._s(object.objektnummer_label) + ": "
                                ),
                                _c("strong", [
                                  _vm._v(_vm._s(object.objektnummer))
                                ])
                              ]),
                              _vm._v(" "),
                              _c("h5", { staticClass: "fw-bold " }, [
                                _vm._v(_vm._s(object.title))
                              ]),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticClass: "badge bg-primary p-2" },
                                [_vm._v(_vm._s(object.objekt_art))]
                              ),
                              _vm._v(" "),
                              _vm._l(object.attributes, function(attribute) {
                                return _c(
                                  "span",
                                  {
                                    key: attribute,
                                    staticClass: "badge bg-dark p-2"
                                  },
                                  [_vm._v(_vm._s(attribute))]
                                )
                              })
                            ],
                            2
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col" }, [
                            _c("p", { staticClass: "fw-bold" }, [
                              _c("span", {
                                staticClass: "fw-bold",
                                domProps: {
                                  innerHTML: _vm._s(object.mietflaeche_gesamt)
                                }
                              }),
                              _vm._v(" | "),
                              _c("span", {
                                domProps: { innerHTML: _vm._s(object.price) }
                              })
                            ])
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          object.price
                            ? _c("div", { staticClass: "col-auto" }, [
                                _c("small", [
                                  _vm._v(_vm._s(object.price_label))
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "m-0" }, [
                                  _c("strong", {
                                    domProps: {
                                      innerHTML: _vm._s(object.price)
                                    }
                                  })
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          object.flaeche_teilbar_ab
                            ? _c("div", { staticClass: "col-auto" }, [
                                _c("small", [
                                  _vm._v(
                                    _vm._s(object.flaeche_teilbar_ab_label)
                                  )
                                ]),
                                _vm._v(" "),
                                _c("p", { staticClass: "m-0" }, [
                                  _c("strong", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        object.flaeche_teilbar_ab
                                      )
                                    }
                                  })
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-auto" })
                        ])
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-auto d-flex align-items-lg-end flex-lg-column justify-content-between"
                      },
                      [
                        _c(
                          "div",
                          {},
                          [_c("favorite", { attrs: { object_id: object.id } })],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex flex-lg-column gap-2" },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "px-4 btn btn-outline-primary",
                                attrs: { href: "tel:" + object.telefon }
                              },
                              [_vm._v(_vm._s(object.telefon))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "px-4 btn btn-outline-primary",
                                attrs: { href: "mailto:" + object.email }
                              },
                              [_vm._v(_vm._s(object.email_label))]
                            ),
                            _vm._v(" "),
                            _c(
                              "a",
                              {
                                staticClass: "px-4 btn btn-primary",
                                attrs: { href: object.url }
                              },
                              [_vm._v(_vm._s(object.url_label))]
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }