var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "row search-params-container align-items-center justify-content-between"
      },
      [
        _c(
          "div",
          { staticClass: "col-lg-6 mb-2 mb-lg-0 d-flex align-items-center" },
          [
            _c(
              "h5",
              {
                staticClass: "fw-bold mb-0 text-uppercase d-inline-block me-2"
              },
              [_vm._v(_vm._s(_vm.offers_label) + ":")]
            ),
            _vm._v(" "),
            _c("v-select", {
              attrs: {
                reduce: function(city) {
                  return city.city
                },
                label: "label",
                value: this.$store.state.city,
                multiple: true,
                options: _vm.options
              },
              on: { input: _vm.setSelected }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto text-center d-none d-lg-block" }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-auto text-lg-end justify-content-between d-flex align-items-center"
          },
          [
            _c("objects-per-page"),
            _vm._v(" "),
            _c("order-by", {
              attrs: { labels: JSON.parse(_vm.order_by_labels) }
            })
          ],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }