var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "favorite-nav" }, [
    _c("a", { staticClass: "nav-link px-0", attrs: { href: _vm.href } }, [
      _vm._v("\n    " + _vm._s(_vm.link_label) + "\n    "),
      _c(
        "span",
        { staticClass: "favorite" },
        [
          _c("font-awesome-icon", {
            class: _vm.hasFavorites ? "text-primary" : "favorite",
            attrs: { icon: ["fas", "star"] }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }