var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "objects-per-page" },
    _vm._l(_vm.options, function(option) {
      return _c(
        "span",
        {
          key: option,
          class: option === _vm.currentOption ? "fw-bold" : "",
          on: {
            click: function($event) {
              return _vm.setPerPage(option)
            }
          }
        },
        [_vm._v(_vm._s(option) + "\n  ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }