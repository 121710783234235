var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search-params-reduced" }, [
    _c("div", {}, [
      _c(
        "div",
        {
          staticClass: "btn-group",
          attrs: { role: "group", "aria-label": "Basic example" }
        },
        [
          _c(
            "button",
            {
              class: _vm.isOffice
                ? "btn bg-primary text-white"
                : "btn bg-transparent text-tertiary",
              attrs: { type: "button" },
              on: { click: _vm.toggleDepartment }
            },
            [_vm._v(_vm._s(_vm.office_label) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: !_vm.isOffice
                ? "btn bg-primary text-white"
                : "btn bg-transparent text-tertiary",
              attrs: { type: "button" },
              on: { click: _vm.toggleDepartment }
            },
            [_vm._v(_vm._s(_vm.logistics_label) + "\n    ")]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "bg-primary py-35 " }, [
      _c(
        "div",
        {
          staticClass:
            "gap-3 search-params-container-top px-search-params align-items-center d-flex flex-row justify-content-between"
        },
        [
          _c(
            "div",
            { staticClass: " w-100 mb-2 mb-lg-0  align-items-center" },
            [
              _c("v-select", {
                staticClass: "w-100 search-city",
                attrs: {
                  onchange: "",
                  placeholder: _vm.placeholder_label,
                  reduce: function(city) {
                    return city.city
                  },
                  label: "label",
                  value: this.$store.state.city,
                  multiple: true,
                  options: _vm.options
                },
                on: { input: _vm.setSelected }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "d-grid" }, [
              _c(
                "button",
                {
                  staticClass: "btn bg-white search-submit text-primary",
                  on: { click: _vm.search }
                },
                [_vm._v(_vm._s(this.search_label))]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }