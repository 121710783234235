//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select';

export default {
  data() {
    return {
      query: '',
    }
  },
  components: {
    vSelect,
  },

  methods: {
    setSelected(value) {
      this.$store.dispatch('setCity', value)
    },
    toggleDepartment() {
      this.$store.state.city = null;
      this.$store.dispatch('toggleDepartment',)
    },
    search() {
      this.$store.dispatch('getMarkers');
      this.$store.dispatch('getObjects');
    },
  },

  computed: {
    options() {
      return this.$store.state.suggestions
    },
    isOffice() {
      return this.$store.state.office
    },
  },

  mounted: function () {
    this.$store.dispatch('getSuggestions')
  },
  props: ['search_label', 'order_by_labels', 'placeholder_label',  'office_label', 'logistics_label'],
}
