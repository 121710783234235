//
//
//
//
//
//
//
//
//

export default {
  props: [
    'link_label', 'href',
  ],
  data() {
    return {
      star: 'far fa-star',
    }
  },
  computed: {
    hasFavorites() {
      return this.$store.state.favorites.length > 0;
    },
  },
}
