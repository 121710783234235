var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mb-4" }, [
    _c("div", { staticClass: "col-auto" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-outline-primary mb-2 mb-lg-0",
          on: { click: _vm.toggleFavorite }
        },
        [
          _c("span", { staticClass: "pe-4  d-inline-block" }, [
            _vm._v(
              _vm._s(this.object_number_label) +
                ": " +
                _vm._s(this.object_number)
            )
          ]),
          _vm._v("\n      " + _vm._s(this.add_favorite_label) + "\n\n      "),
          _c(
            "span",
            { staticClass: "favorite" },
            [
              _c("font-awesome-icon", {
                class: _vm.isFavorite ? "text-primary" : "favorite",
                attrs: { icon: ["fas", "star"] }
              })
            ],
            1
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col d-flex justify-content-lg-end" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-center me-2 bg-mercury contact-person px-2"
        },
        [
          _c("strong", { staticClass: "me-2" }, [
            _vm._v(_vm._s(_vm.name_label))
          ]),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.vorname) + " " + _vm._s(_vm.name))])
        ]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "me-2 btn btn-outline-primary",
          attrs: { href: "tel:" + _vm.telefon }
        },
        [_vm._v(_vm._s(_vm.telefon))]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: " btn btn-outline-primary",
          attrs: { href: "mailto:" + _vm.email }
        },
        [_vm._v(_vm._s(_vm.email_label))]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }