//
//
//
//
//
//
//
//
//
//
//


export default {
  props: ['labels'],
  methods : {
    setSortOrder: function(event) {
      this.$store.dispatch('setSortOrder', event.target.value);
    },
  },
}
