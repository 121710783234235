//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      star: 'far fa-star',
      isFavorite: false,
    }
  },
  props: ['thumbnail', 'object_number_label', 'add_favorite_label', 'object_number', 'vorname', 'name', 'name_label', 'telefon', 'email', 'email_label'],
  methods: {
    toggleFavorite: function () {
      this.isFavorite = !this.isFavorite;
    },
  },
}
