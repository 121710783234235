//
//
//
//

export default {
  props: [
    'object_id',
  ],
  data() {
    return {
      star: 'far fa-star',
    }
  },

  computed: {
    isFavorite() {
      if (!Array.isArray(this.$store.state.favorites)) {
        return false;
      }
      return this.$store.state.favorites.includes(this.object_id);
    },
  },
  methods: {
    toggleFavorite: function () {
        this.$store.dispatch('toggleFavorite', this.object_id)
    },
  },
}
