//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ['phone', 'phone_label', 'mobile', 'mobile_label', 'email', 'email_label'],
}
