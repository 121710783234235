var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-container" },
    [
      _c(
        "gmaps-map",
        { on: { mounted: _vm.mapReady } },
        _vm._l(_vm.markers, function(marker) {
          return _c("gmaps-marker", {
            key: marker.id,
            ref: "",
            refInFor: true,
            attrs: {
              options: { title: marker[0].plz },
              position: { lat: marker[0].loc.lat, lng: marker[0].loc.lng },
              icon:
                "//chart.googleapis.com/chart?chst=d_map_pin_letter&chld=" +
                marker.length +
                "|d7025c|000000"
            },
            on: { click: _vm.setPlz }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }