import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import VuexPersistence from 'vuex-persist';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  key: 'brockhoff080524',
})

Vue.use(Vuex)

const actions = {
  getObjects({state, dispatch, commit}) {
    axios.get('/wp-json/wp/v2/object/', {
      params: {
        page: state.page,
        per_page: state.per_page,
        order_by: state.order_by,
        plz: state.plz,
        city: state.city,
        office: state.office,
        objektnr: state.objektnummer,
      },
    })
      .then(response => {
        commit('SET_OBJECTS', {
          objects: response.data,
          total: response.headers['x-wp-total'],
          total_pages: response.headers['x-wp-totalpages'],
        })
        dispatch('getMarkers')
      })
  },

  getFavorites({state, dispatch, commit}) {
    var fav = state.favorites;
    if (state.favorites.length === 0) {
      fav = '0';
    }

    axios.get('/wp-json/wp/v2/object/', {
      params: {
        favorites: fav,
      },
    })
      .then(response => {
        commit('SET_OBJECTS', {
          objects: response.data,
          total: response.headers['x-wp-total'],
          total_pages: response.headers['x-wp-totalpages'],
        })
        dispatch('getMarkersFavorites')
      })
  },

  getMarkers({state, commit}) {
    axios.get('/wp-json/wp/v2/object/', {
      params: {
        page: state.page,
        per_page: state.per_page,
        order_by: state.order_by,
        plz: state.plz,
        geo: true,
        city: state.city,
        office: state.office,
      },
    })
      .then(response => {
        commit('SET_MARKERS',
          response.data,
        )
      })
  },

  getMarkersFavorites({state, commit}) {
    axios.get('/wp-json/wp/v2/object/', {
      params: {
        geo: true,
        favorites: state.favorites,
      },
    })
      .then(response => {
        commit('SET_MARKERS',
          response.data,
        )
      })
  },

  getSuggestions({state, commit}) {
    axios.get('/wp-json/wp/v2/object/', {
      params: {
        suggestions: true,
        office: state.office,
      },
    })
      .then(response => {
        commit('SET_SUGGESTIONS',
          response.data,
        )
      })
  },

  toggleFavorite({dispatch, commit}, favorite) {
    commit('TOGGLE_FAVORITE', favorite);
    if (document.body.classList.contains('favorites') ||
      document.body.classList.contains('favoriten')
    ) {
      dispatch('getFavorites');
    }
  },

  setPage({dispatch, commit}, page) {
    commit('SET_PAGE', page);
    dispatch('getObjects');
  },

  setPerPage({dispatch, commit}, per_page) {
    commit('SET_PER_PAGE', per_page);
    commit('SET_PAGE', 1)
    dispatch('getObjects');
  },

  setSortOrder({dispatch, commit}, order_by) {
    commit('SET_SORT_ORDER', order_by);
    dispatch('getObjects');
  },

  setPlz({dispatch, commit}, val) {
    commit('SET_PLZ', val);
    dispatch('getObjects');
  },

  setCity({dispatch, commit}, val) {
    commit('SET_CITY', val);
    dispatch('getObjects');
  },
  toggleDepartment({dispatch, commit}) {
    commit('TOGGLE_DEPARTMENT');
    commit('SET_PAGE', 1)
    dispatch('getObjects');
    dispatch('getSuggestions')
  },
}

const mutations = {
  SET_OBJECTS(state, payload) {
    state.objects = payload.objects;
    state.total = payload.total;
    state.total_pages = payload.total_pages;
  },

  SET_MARKERS(state, markers) {
    state.markers = markers;
  },

  TOGGLE_FAVORITE(state, favorite) {
    if (!Array.isArray(state.favorites)) {
      return false;
    }

    if (state.favorites.includes(favorite)) {
      state.favorites.splice(state.favorites.indexOf(favorite), 1);
    } else {
      state.favorites.push(favorite)
    }


  },

  ADD_FAVORITE(state, favorite) {
    state.favorites.push(favorite)
  },

  REMOVE_FAVORITE(dispatch, state, favorite) {
    state.favorites.splice(state.favorites.indexOf(favorite));

    if (document.body.classList.contains('favorites') ||
      !document.body.classList.contains('favoriten')
    ) {
      dispatch('getFavorites');
    }

  },

  SET_PAGE(state, page) {
    state.page = page;
  },
  SET_PER_PAGE(state, per_page) {
    state.per_page = per_page;
  },
  SET_SORT_ORDER(state, order_by) {
    state.order_by = order_by;
  },
  SET_PLZ(state, plz) {
    state.plz = plz;
  },
  SET_SUGGESTIONS(state, suggestions) {
    state.suggestions = suggestions;
  },

  SET_CITY(state, city) {
    console.log('City state before mutation:', state.city);

    state.city = city;
    console.log('City state after mutation:', state.city);

  },
  TOGGLE_DEPARTMENT(state) {
    state.office = !state.office;
  },

  SET_DEPARTMENT(state, office) {
    state.office = office;
    state.page = 1;
  },

  SET_OBJEKTNUMMER(state, objektnummer) {
    state.objektnummer = objektnummer;
  },
}

export default new Vuex.Store({
  state: {
    // snippet data
    objects: [],
    // post ids
    favorites: [],
    // total objects
    total: 0,
    total_pages: 0,
    page: 1,
    per_page: 12,
    order: 0,
    markers: [],
    plz: '',
    suggestions: [],
    city: [],
    office: !document.body.classList.contains('logistics'),

  },
  actions,
  mutations,
  plugins: [vuexLocal.plugin],
})
