//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {gmapsMap, gmapsMarker} from 'x5-gmaps';

export default {
  components: {gmapsMap, gmapsMarker},
  data() {
    return {map: null}
  },
  props: [''],
  computed: {
    markers() {
      return this.$store.state.markers
    },
  },
  watch: {
    markers() {
      this.center_map();

    },
  },

  methods: {
    setPlz(val) {
      this.$store.dispatch('setPlz', val.domEvent.target.parentNode.getAttribute('title'));
    },
    mapReady(map) {
      this.map = map;

    },
    center_map() {

      // vars
      var bounds = new window.google.maps.LatLngBounds();

      Object.keys(this.markers).forEach(function (key) {
        var latlng = new window.google.maps.LatLng(this.markers[key][0].loc.lat, this.markers[key][0].loc.lng);
        bounds.extend(latlng);
      }, this);

      // only 1 marker?
      if (Object.keys(this.markers).length === 1) {
        // set center of map
        this.map.setCenter(bounds.getCenter());
        this.map.setZoom(14);
      } else {
        // fit to bounds
        console.log(this.markers.length);
        this.map.fitBounds(bounds);
      }

    },
  },

  mounted() {
    this.$store.dispatch('getMarkers')
  },
}
