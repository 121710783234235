//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      star: 'far fa-star',
    }
  },
  props: ['object_id', 'object_number_label', 'add_favorite_label', 'object_number', 'vorname', 'name', 'name_label', 'telefon', 'email', 'email_label'],
  computed: {
    isFavorite() {
      if (!Array.isArray(this.$store.state.favorites)) {
        return false;
      }

      return this.$store.state.favorites.includes(this.object_id);
    },
  },
  methods: {
    toggleFavorite: function () {
      this.$store.dispatch('toggleFavorite', this.object_id)
    },
  },
}
