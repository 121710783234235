//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select';

export default {
  data() {
    return {
      query: '',
    }
  },
  components: {
    vSelect,
  },

  methods: {
    setSelected(value) {
      this.$store.commit('SET_CITY', value)
    },
    toggleDepartment() {
      this.$store.state.city = null;
      this.$store.commit('TOGGLE_DEPARTMENT')
      this.$store.dispatch('getSuggestions')
    },
    search() {

      let suffix = '';
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get('lang')) {
        suffix = '?lang=' + urlParams.get('lang');
      } else {
        // eslint-disable-next-line no-unused-vars
        suffix = '';
      }

      if (this.$store.state.office) {
        window.location.href = '/office/' + suffix;
      } else {
        window.location.href = '/logistics/' + suffix;
      }
    },
  },

  computed: {
    options() {
      return this.$store.state.suggestions
    },
    isOffice() {
      return this.$store.state.office
    },
  },

  mounted: function () {
    this.$store.dispatch('getSuggestions')
  },
  props: ['search_label', 'placeholder_label'],
}
