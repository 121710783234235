//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {Swiper, SwiperSlide} from 'vue-awesome-swiper'

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  props: ['show_favorites', 'phone', 'phone_label', 'mobile', 'mobile_label', 'email', 'email_label', 'department', 'city', 'objektnummer'],

  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  computed: {
    objects() {
      return this.$store.state.objects
    },

    isMobile() {
        return window.matchMedia('(max-width: 768px)').matches;
    },

  },


  mounted() {
    if (!this.show_favorites) {

      if (document.body.classList.contains('template-mietangebot')) {
        this.$store.dispatch('setCity', []);
        this.$store.commit('SET_OBJEKTNUMMER', this.objektnummer);
        this.$store.dispatch('setPerPage', 100);
        this.$store.dispatch('getObjects')

        return;
      } else {
        this.$store.commit('SET_OBJEKTNUMMER', null);
        this.$store.dispatch('setPerPage', 12);
      }


      if (this.$store.state.office !== !document.body.classList.contains('logistics')) {
        if(!document.body.classList.contains('template-landing')
          && !document.body.classList.contains('template-landing-office')
          && !document.body.classList.contains('template-landing-logistics')


        ) {
          this.$store.dispatch('setCity', [])
        }
      }

      if(document.body.classList.contains('template-landing')) {
        console.log('setting city to ', this.city)
        this.$store.commit('SET_CITY', [this.city])
      } else {
       if( !document.body.classList.contains('template-landing-office')
        && !document.body.classList.contains('template-landing-logistics') ) {
         this.$store.dispatch('setCity', [])
       }
      }


      if (this.department !== undefined) {
        this.$store.commit('SET_DEPARTMENT', this.department === 'office')
      } else {
        this.$store.commit('SET_DEPARTMENT', !document.body.classList.contains('logistics'))
      }
      this.$store.dispatch('getObjects')
      this.$store.dispatch('getSuggestions')

    } else {
      this.$store.dispatch('getFavorites')
    }
  },
}
