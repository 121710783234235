//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vSelect from 'vue-select';

export default {
  data() {
    return {
      query: '',
    }
  },
  components: {
    vSelect,
  },

  methods: {
    setSelected(value) {
      this.$store.dispatch('setCity', value)
    },
  },

  computed: {
    options() {
      return this.$store.state.suggestions
    },
  },

  mounted: function () {
      this.$store.dispatch('getSuggestions')
  },
  props: ['offers_label', 'order_by_labels'],
}
