var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "d-grid gap-2" }, [
          _c(
            "a",
            {
              staticClass: " btn bg-accent btn-outline-primary",
              attrs: { href: "tel:" + _vm.phone }
            },
            [_vm._v(_vm._s(_vm.phone_label))]
          ),
          _vm._v(" "),
          _vm.mobile
            ? _c(
                "a",
                {
                  staticClass: "bg-accent btn btn-outline-primary",
                  attrs: { href: "tel:" + _vm.mobile }
                },
                [_vm._v(_vm._s(_vm.mobile_label))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "bg-accent btn btn-outline-primary",
              attrs: { href: "mailto:" + _vm.email }
            },
            [_vm._v(_vm._s(_vm.email_label))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }