var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "select",
      {
        staticClass: "form-select",
        attrs: { "aria-label": "Sortierung" },
        on: { change: _vm.setSortOrder }
      },
      [
        _c("option", { attrs: { selected: "" } }, [_vm._v("Sortierung")]),
        _vm._v(" "),
        _c("option", { attrs: { value: "1" } }, [
          _vm._v(_vm._s(_vm.labels.flaeche) + " " + _vm._s(_vm.labels.asc))
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "2" } }, [
          _vm._v(_vm._s(_vm.labels.flaeche) + " " + _vm._s(_vm.labels.desc))
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "3" } }, [
          _vm._v(_vm._s(_vm.labels.date_new))
        ]),
        _vm._v(" "),
        _c("option", { attrs: { value: "4" } }, [
          _vm._v(_vm._s(_vm.labels.date_old))
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }