var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-search my-4" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        {
          staticClass:
            "col-auto text-end justify-content-between d-flex align-items-center"
        },
        [
          _c(
            "div",
            {
              staticClass: "btn-group",
              attrs: { role: "group", "aria-label": "Basic example" }
            },
            [
              _c(
                "button",
                {
                  class: _vm.isOffice
                    ? "btn bg-white text-primary"
                    : "btn bg-transparent text-white ",
                  attrs: { type: "button" },
                  on: { click: _vm.toggleDepartment }
                },
                [_vm._v("Office\n        ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: !_vm.isOffice
                    ? "btn bg-white text-primary"
                    : "btn bg-transparent text-white",
                  attrs: { type: "button" },
                  on: { click: _vm.toggleDepartment }
                },
                [_vm._v("Logistics\n        ")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn bg-transparent text-white",
                  attrs: { href: "/retail/" }
                },
                [_vm._v("Retail")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn bg-transparent text-white",
                  attrs: { href: "/invest/" }
                },
                [_vm._v("Invest")]
              )
            ]
          )
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "row bg-white search-params-container-top flex-column p-4 rounded shadow align-items-center justify-content-end"
      },
      [
        _c(
          "div",
          { staticClass: "col d-flex align-items-center" },
          [
            _c("v-select", {
              staticClass: "w-100",
              attrs: {
                placeholder: _vm.placeholder_label,
                reduce: function(city) {
                  return city.city
                },
                label: "label",
                value: this.$store.state.city,
                multiple: true,
                options: _vm.options
              },
              on: { input: _vm.setSelected }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col d-flex justify-content-end" }, [
          _c(
            "button",
            { staticClass: "btn bg-aaa text-white", on: { click: _vm.search } },
            [_vm._v(_vm._s(this.search_label))]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }