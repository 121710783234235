//
//
//
//
//
//

export default {
  computed: {
    isObjectsArrayNotEmpty() {
      return this.$store.state.objects.length > 0;
    },
  },
};
