var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "favorite me-2 me-lg-0", on: { click: _vm.toggleFavorite } },
    [
      _c("font-awesome-icon", {
        class: _vm.isFavorite ? "text-primary" : "favorite",
        attrs: { icon: ["fas", "star"] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }