import { CountUp } from 'countup.js';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

class CountUpWrapper {

  constructor(id) {
    this.id = id;
  }


  countStart() {
    $('#'+this.id).addClass('active');

    $('#'+this.id).find('.col-md-3[data-count]').each(function () {
      var item = this;

      var countValue = jQuery(item).data('count');


      var countUP = new CountUp(jQuery(item).find('span.number')[0],countValue, {
        useEasing: true,
        useGrouping: false,
        decimalPlaces: jQuery(item).attr('data-decimal-places') == null ? false : jQuery(item).attr('data-decimal-places'),
        decimal: jQuery(item).attr('data-seperator') == null ? ',' : ',',
        startVal: 0,

      });
      if (!countUP.error) {
        ScrollTrigger.create({
          trigger: item,
          start: 'top bottom',
          onToggle: function() {countUP.start()},
        });

      }
    });
  }
}

export default CountUpWrapper;
