//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LightGallery } from 'vue-light-gallery';

export default {
  components: {
    LightGallery,
  },
  props: ['images'],
  data() {
    return {
      index: null,
    };
  },
  methods: {
    openFirst: function () {
      // `this` inside methods points to the Vue instance
      this.index = 0;
    },
  },
};
