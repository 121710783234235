//
//
//
//
//
//
//
//
//
//

export default {
  data()  {
    return {
      options: [12, 24, 36, 48, 60],
    }
  },
  computed : {
    currentOption() {
      return this.$store.state.per_page;
    },
  },
  methods: {
    setPerPage: function (val) {
      this.$store.dispatch('setPerPage', val);
    },
  },
}
