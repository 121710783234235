var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery mb-4" },
    [
      _c("LightGallery", {
        attrs: { images: _vm.images, index: _vm.index, "disable-scroll": true },
        on: {
          close: function($event) {
            _vm.index = null
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-4" }, [
        _c("div", { staticClass: "col" }, [
          _c("img", {
            staticClass: "img-fluid",
            attrs: { src: _vm.images[0].url },
            on: { click: this.openFirst }
          })
        ])
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row row-cols-5 gy-4" },
        _vm._l(_vm.images, function(thumb, thumbIndex) {
          return _c(
            "div",
            {
              key: thumbIndex,
              staticClass: "col",
              on: {
                click: function($event) {
                  _vm.index = thumbIndex
                }
              }
            },
            [
              _c("img", {
                staticClass: "img-fluid",
                attrs: { src: thumb.thumbnail }
              })
            ]
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }