var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return this.$store.state.objects.length > 0
    ? _c(
        "div",
        {
          staticClass:
            "mb-4 d-flex b-pagination align-items-center justify-content-between"
        },
        [
          _c("span", { staticClass: "ms-0 align-self-start" }, [
            _vm._v(
              _vm._s(_vm.total_objects) +
                " " +
                _vm._s(_vm.total_label) +
                " " +
                _vm._s(_vm.total_available_label)
            )
          ]),
          _vm._v(" "),
          _c("v-pagination", {
            attrs: {
              classes: _vm.bootstrapPaginationClasses,
              "page-count": _vm.total
            },
            model: {
              value: _vm.currentPage,
              callback: function($$v) {
                _vm.currentPage = $$v
              },
              expression: "currentPage"
            }
          }),
          _vm._v(" "),
          _c("div")
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }