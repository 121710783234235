// import external dependencies
import Vue from 'vue';
import Vuex from 'vuex';
import store from './vue/store/index';
import {addBackToTop} from 'vanilla-back-to-top';

import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

addBackToTop({
  backgroundColor: '#d7025c',
})
import {install} from 'x5-gmaps';

Vue.use(install, 'AIzaSyAileezu856d99Wpdo2zveBvFoHVAzswzY');

import 'jquery';
import {library, dom} from '@fortawesome/fontawesome-svg-core';
import {
  faCheck,
  faStar as faStarSolid,
  faPlusCircle,
  faChevronRight,
  faVideo,
  faInbox,
  faMailBulk,
  faChartBar,
  faChartLine,
  faCar,
  faCoins,
  faMugHot,
  faTemperatureLow,
  faUserGraduate,
  faSortAmountUp, faTablet, faCalendar, faPenFancy, faFileSignature, faUserFriends, faPeopleArrows,
} from '@fortawesome/free-solid-svg-icons';
import {faStar, faCopy, faNewspaper, faLemon} from '@fortawesome/free-regular-svg-icons';
import {faInstagram, faLinkedin, faXing, faTwitter, faFacebook, faApple} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

// Import everything from autoload
import './autoload/_bootstrap.js'

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import CountUpWrapper from './util/counter';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());

/* Font Awesome Icons */
library.add(faCheck,
  faStarSolid,
  faStar,
  faChevronRight,
  faChartBar,
  faCopy,
  faChartLine,
  faNewspaper,
  faPlusCircle, faInstagram, faLinkedin, faXing, faTwitter, faFacebook, faVideo, faInbox, faMailBulk,
  // career
  faCar,
  faCoins,
  faMugHot,
  faTemperatureLow,
  faUserGraduate,
  faLemon,
  faApple,
  faSortAmountUp,
  faTablet,
  faCalendar,
  faPenFancy,
  faFileSignature,
  faUserFriends,
  faPeopleArrows,

);
dom.watch();


Vue.use(Vuex);

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('gallery', require('./vue/gallery.vue').default);
Vue.component('header-search', require('./vue/header-search.vue').default);
Vue.component('object-interact', require('./vue/object-interact.vue').default);
Vue.component('object-contact', require('./vue/object-contact.vue').default);
Vue.component('team-contact', require('./vue/team-contact.vue').default);
Vue.component('list', require('./vue/list.vue').default);
Vue.component('favorite', require('./vue/favorite.vue').default);
Vue.component('favorite-nav', require('./vue/favorite-nav.vue').default);
Vue.component('pagination', require('./vue/pagination.vue').default);
Vue.component('search-params-top', require('./vue/search-params-top.vue').default);
Vue.component('search-params-top-reduced', require('./vue/search-params-top-reduced.vue').default);
Vue.component('reduced-wrapper', require('./vue/reduced-wrapper.vue').default);
Vue.component('search-params', require('./vue/search-params.vue').default);
Vue.component('objects-per-page', require('./vue/objects_per_page.vue').default);
Vue.component('order-by', require('./vue/order-by.vue').default);
Vue.component('gmap', require('./vue/gmap.vue').default);

new Vue({
  el: '#app',
  store: store,
});

if (window.matchMedia('(max-width: 576px)').matches) {

  document.addEventListener('DOMContentLoaded', function () {
    window.addEventListener('scroll', function () {
      if (window.scrollY > 50) {
        document.getElementById('sticky-contact').classList.add('fixed-top');
        var navbar_height = document.querySelector('.navbar').offsetHeight;
        document.body.style.paddingTop = navbar_height + 'px';
      } else {
        document.getElementById('sticky-contact').classList.remove('fixed-top');
        document.body.style.paddingTop = '0';
      }
    });
  });
}

new CountUpWrapper('counter-container').countStart();

gsap.utils.toArray('.career-breakout').forEach((section) => {

  gsap.to(section, {
    backgroundPosition: '50% 100%',
    ease: 'none',
    scrollTrigger: {
      trigger: section,
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
    },
  });
});


