var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row mb-4" }, [
      _c("div", { staticClass: "col d-grid" }, [
        _c(
          "button",
          {
            staticClass: "btn d-block btn-outline-primary",
            on: { click: _vm.toggleFavorite }
          },
          [
            _c("span", { staticClass: "pe-4 d-inline-block" }, [
              _vm._v(
                _vm._s(this.object_number_label) +
                  ": " +
                  _vm._s(this.object_number)
              )
            ]),
            _vm._v("\n      " + _vm._s(this.add_favorite_label) + "\n      "),
            _c(
              "span",
              { staticClass: "favorite float-end" },
              [
                _c("font-awesome-icon", {
                  class: _vm.isFavorite ? "text-primary" : "favorite",
                  attrs: { icon: ["fas", "star"] }
                })
              ],
              1
            )
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-4" }, [
      _c(
        "div",
        {
          staticClass:
            "col-12 col-sm-auto d-flex justify-content-center justify-content-lg-end"
        },
        [
          _c("img", {
            staticClass: "img-fluid mb-2",
            attrs: { src: _vm.thumbnail }
          })
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("div", { staticClass: "d-grid gap-2" }, [
          _c("div", { staticClass: "bg-mercury contact-person  p-2" }, [
            _c("strong", { staticClass: "me-2" }, [
              _vm._v(_vm._s(_vm.name_label))
            ]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(_vm.vorname) + " " + _vm._s(_vm.name))])
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: " btn btn-outline-primary",
              attrs: { href: "tel:" + _vm.telefon }
            },
            [_vm._v(_vm._s(_vm.telefon))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: " btn btn-outline-primary",
              attrs: { href: "mailto:" + _vm.email }
            },
            [_vm._v(_vm._s(_vm.email_label))]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }